<template>
  <div class="clients">
    <div v-if="loader" class="load">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="snackbar"></div>
    <div v-if="panelShow" class="toolPanel" style="z-index: 3">
      <div class="cc" style="padding: 0; width: 90%">
        <div class="x img" style="color: red" v-on:click="hideShow">X</div>
        <div class="testsAnCnt" style="display: flex; width: 100%">
          <div class="testsBar">
            <div>Menu</div>
            <div class="testsscroll" style="overflow-x: auto">
              <div
                v-for="tst in test"
                :key="tst.idTest"
                @click="selectTest(tst.LibelleNiveau, tst.LibelleMatiere)"
                :class="tst.end == '0' || tst.result == '0' ? 'hiddenDiv' : ''"
                :style="tst.idClient != selected.idClient ? 'display:none' : ''"
              >
                {{ tst.LibelleMatiere + " " + tst.LibelleNiveau }}
                <span style="display: none">
                  {{
                    tst.idClient == selected.idClient
                      ? (annLvl = tst.LibelleNiveau)
                      : null
                  }}
                </span>
              </div>
              <div v-if="selected.tested == '0'" class="nodata">
                Aucun tests pour Client id '{{ selected.idClient }}'
              </div>
              <div @click="Analyse()" v-if="selected.tested == '1'">
                <span
                  style="
                    margin: auto;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                  "
                >
                  <img
                    style="margin-right: 5px"
                    src="@/assets/icons/analyse.png"
                    width="30"
                    alt=""
                  />Analyse
                </span>
              </div>
            </div>
          </div>
          <div v-if="!Stest.idTest && !ann" class="testsCnt">
            <div style="margin-top: 20px; color: white; font-size: 22px">
              Choisie un niveau
            </div>
          </div>
          <div v-if="Stest.idTest" class="testsCnt">
            <div style="margin-top: 20px; color: white; font-size: 22px">
              {{ Stest.LibelleMatiere + " " + Stest.LibelleNiveau }} Details
            </div>
            <div>
              <button
                style="padding: 5px 10px;background-color:#7ee47e;margin: 10px;font-weight:bold,font-size:18px;cursor:pointer"
                @click="toPdf"
              >
                <i style="margin-right: 5px; font-size: 19px" class="fa fa-download"></i
                >Imprimer
              </button>
            </div>
            <div id="detPdf">
              <div style="display: flex; align-items: center">
                <div style="text-align: center; width: fit-content">
                  <img
                    id="img"
                    style="width: 200px"
                    src="https://api.victory-partners.ma/victory%20api/upload/images/icons/logo.png"
                    alt=""
                  />
                  <div style="width: fit-content; display: table-cell">
                    {{
                      Stest.LibelleMatiere != "Anglais"
                        ? "Bienvenue au centre"
                        : "Welcome in"
                    }}
                    VICTORY-Partners berrechid
                  </div>
                </div>
                <h1 style="margin: 10px auto">
                  Test
                  {{ Stest.LibelleMatiere != "Anglais" ? "concernant" : "concerning" }}
                  {{ Stest.NomClient }}
                </h1>
              </div>
              <div
                style="
                  text-align: center;
                  display: grid;
                  grid-template-columns: 33% 33% 33%;
                  margin: 10px 0;
                  font-size: 18px;
                "
              >
                <span style="text-align: center"
                  >{{ Stest.LibelleMatiere != "Anglais" ? "Matière" : "Language" }}:
                  {{ Stest.LibelleMatiere }}</span
                >
                <span style="text-align: center"
                  >{{ Stest.LibelleMatiere != "Anglais" ? "Niveau" : "Level" }}:
                  {{ Stest.LibelleNiveau }}</span
                >
                <span style="text-align: center"
                  >{{
                    Stest.LibelleMatiere != "Anglais" ? "Date de test" : "Test date"
                  }}: {{ Stest.DateTest }}</span
                >
              </div>
              <div
                style="
                  text-align: center;
                  font-size: 18px;
                  font-weight: bold;
                  margin: 20px 0;
                "
              >
                {{
                  Stest.LibelleMatiere != "Anglais" ? "Résultat Test" : "Test result"
                }}:
                {{ Stest.result }}
              </div>
              <table
                id="Tabledet"
                border="1"
                style="
                  background-color: white;
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 30px;
                "
              >
                <thead>
                  <tr style="cursor: initial">
                    <th></th>
                    <th>
                      {{ Stest.LibelleMatiere != "Anglais" ? "Ennoncé" : "formulation" }}
                    </th>
                    <th>
                      {{
                        Stest.LibelleMatiere != "Anglais"
                          ? "Bonne Réponse"
                          : "right answer"
                      }}
                    </th>
                    <th>
                      {{
                        Stest.LibelleMatiere != "Anglais"
                          ? "Client Réponse"
                          : "Client answer"
                      }}
                    </th>
                    <th>
                      {{ Stest.LibelleMatiere != "Anglais" ? "Résultat" : "Result" }}
                    </th>
                  </tr>
                </thead>
                <div>
                  <tr v-for="d in det" :key="d.idTest">
                    <td>
                      <div
                        :style="
                          d.type == 'comp'
                            ? 'color:green'
                            : d.type == 'qcm'
                            ? 'color:red'
                            : d.type == 'audio'
                            ? 'color:blue'
                            : ''
                        "
                      >
                        {{
                          Stest.LibelleMatiere != "Anglais"
                            ? d.type == "comp"
                              ? "Compréhension écrite"
                              : d.type == "qcm"
                              ? "Structure de la langue"
                              : d.type == "audio"
                              ? "Compréhension orale"
                              : ""
                            : d.type == "comp"
                            ? "Written comprehension"
                            : d.type == "qcm"
                            ? "Language structure"
                            : d.type == "audio"
                            ? "Oral comprehension"
                            : ""
                        }}
                      </div>
                    </td>
                    <td>{{ d.ennonce ? d.ennonce : "null" }}</td>
                    <td>{{ d.bonne ? d.bonne : "null" }}</td>
                    <td>{{ d.response ? d.response : "null" }}</td>
                    <td style="text-align: center">
                      <img
                        v-if="d.bonne == d.response"
                        style="height: 20px"
                        src="https://api.victory-partners.ma/victory%20api/upload/images/icons/true.png"
                        alt=""
                      />
                      <img
                        v-if="d.bonne != d.response"
                        style="height: 20px"
                        src="https://api.victory-partners.ma/victory%20api/upload/images/icons/false.png"
                        alt=""
                      />
                    </td>
                  </tr>
                </div>
              </table>
            </div>
          </div>
          <div v-if="ann" class="testsCnt">
            <div id="annPdf">
              <div v-for="(item, index) in allAnn" :key="index">
                <h2 style="text-align: center; margin: 20px 0">
                  {{item.LibelleMatiere}} :<button
                    style="padding: 5px 10px;background-color:#7ee47e;margin: 10px;font-weight:bold,font-size:18px;cursor:pointer"
                    @click="toAnnPdf(item.idMatiere+'Pdf')"
                  >
                    <i
                      style="margin-right: 5px; font-size: 19px"
                      class="fa fa-download"
                    ></i
                    >Imprimer
                  </button>
                </h2>
                <div :id="item.idMatiere+'Pdf'">
                  <div style="display: flex; align-items: center">
                    <div style="text-align: center; width: fit-content">
                      <img
                        id="img"
                        style="width: 200px"
                        src="https://api.victory-partners.ma/victory%20api/upload/images/icons/logo.png"
                        alt=""
                      />
                      <div style="width: fit-content; display: table-cell">
                        {{item.idMatiere == 9?'Welcome in VICTORY-Partners Berrechid':'Bienvenue au centre VICTORY-Partners Berrechid'}}
                      </div>
                    </div>
                    <h1 style="margin: 10px auto; display: flex; align-items: center">
                      <img
                        id="img"
                        style="width: 100px"
                        src="https://api.victory-partners.ma/victory%20api/upload/images/icons/analyse.png"
                        alt=""
                      />
                      <span>
                        {{item.idMatiere == 9?'Analysis of english test results concerning ':'Analyse des résultats de test ' + item.LibelleMatiere + ' concernant '}}
                        {{ selected.NomClient }}
                      </span>
                    </h1>
                  </div>
                  <div>{{ annLvl }}</div>
                  <table
                    id="TableAnn"
                    border="1"
                    style="
                      background-color: white;
                      width: 100%;
                      border-collapse: collapse;
                      margin-top: 40px;
                    "
                  >
                    <thead>
                      <tr style="cursor: initial">
                        <th>{{item.idMatiere == 9?'Question type':'Type de question'}}</th>
                        <th>{{item.idMatiere == 9?'Result in %':'Résultat en %'}}</th>
                        <th>{{item.idMatiere == 9?'Observation':'Observasion'}}</th>
                        <th>{{item.idMatiere == 9?'Goals to reach':'Objectifs à atteindre'}}</th>
                      </tr>
                    </thead>
                    <div>
                      <tr v-for="d in item.data" :key="d.type">
                        <td>{{ d.type }}</td>
                        <td>{{ d.percent }}%</td>
                        <td>{{ d.obs }}</td>
                        <td>{{ d.obj }}</td>
                      </tr>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="panelAdd" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideAdd">X</div>
        <div class="ccTitle">Add Client</div>
        <div class="ccChild"><span>Name:</span><input id="addName" type="text" /></div>
        <div class="ccChild">
          <span>Téléphone:</span><input id="addTel" type="text" />
        </div>
        <div class="ccChild"><span>Email:</span><input id="addEmail" type="text" /></div>
        <div class="ccChild">
          <span>Date de naissance:</span><input id="addDate" type="date" />
        </div>
        <div class="ccChild">
          <span>Ville actuelle<label>*</label> :</span>
          <span>
            <div style="padding-right: 15px; width: 200px">
              <input checked @click="autre = false" name="villeA" type="radio" />Berrechid
            </div>
            <input id="autre" @click="autre = true" name="villeA" type="radio" />Autre
          </span>
        </div>
        <div class="ccChild" v-if="autre">
          <span>Entrer votre ville :</span><input type="text" id="addVille" />
        </div>
        <div class="ccChild">
          <span>Intéréser par<label>*</label> :</span>
          <span id="intrs">
            Français<input v-model="fr" type="checkbox" id="fr" /> Anglais
            <input v-model="en" type="checkbox" id="en" />
          </span>
        </div>
        <div v-if="addError" style="color: yellow; text-align: center">
          Name and Email and Date and language required!
        </div>
        <div style="text-align: center">
          <button v-on:click="add" class="btn add">
            <i class="fa fa-plus-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelSupp" class="toolPanel">
      <div class="cc">
        <div class="ccTitle" style="color: red">
          Do you really want to delete client(id:'{{ selected.idClient }}')?
        </div>
        <div style="text-align: center">
          <button v-on:click="delet" class="btn delete">
            <i class="fa fa-trash"></i>
          </button>
          <button v-on:click="hideSupp" class="btn edit">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelSuppA" class="toolPanel">
      <div class="cc">
        <div class="ccTitle" style="color: red">
          Do you really want to delete selected clients?
        </div>
        <div style="text-align: center">
          <button v-on:click="deleteAll" class="btn delete">
            <i class="fa fa-trash"></i>
          </button>
          <button v-on:click="hideSuppA" class="btn edit">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelEdit" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideEdit">X</div>
        <div class="ccTitle">Modifier Client</div>
        <div class="ccChild" v-if="selected.updated">
          <span>Modifier par:</span><span>{{ selected.updated }}</span>
        </div>
        <div v-for="(m, index) in matiers" :key="index" class="ccChild">
          <span>Lien {{m.LibelleMatiere}}: <span v-if="m.disponible==0" style="display: inline-block;font-size: 12px;color: red;">(pas disponible)</span></span
          ><span
            ><button :disabled="m.disponible==0?true:false" v-on:click="copy(m.idMatiere + 'Link')" :class="m.disponible==0?'btn edit enable':'btn edit'">
              <i class="fa fa-sticky-note"></i></button
            ><input
              :id="m.idMatiere + 'Link'"
              type="text"
              :value="'https://test.victory-partners.ma/main/' + m.idMatiere + '/' + selected.idClient"
              disabled
          /></span>
        </div>
        <div class="ccChild">
          <span>Name:</span
          ><input id="editName" type="text" :value="selected.NomClient" />
        </div>
        <div class="ccChild">
          <span>Téléphone:</span
          ><input id="editTel" type="text" :value="selected.TelClient" />
        </div>
        <div class="ccChild">
          <span>Email:</span
          ><input id="editEmail" type="text" :value="selected.EmailClient" />
        </div>
        <div class="ccChild">
          <span>Date de naissance:</span
          ><input id="editDate" type="date" :value="selected.DateClient" />
        </div>
        <div class="ccChild">
          <span>Etat:</span>
          <select id="etat">
            <option value=""></option>
            <option :selected="selected.etat=='Déjà inscrit (e)'" style="background-color: green" value="Déjà inscrit (e)">
              Déjà inscrit (e)
            </option>
            <option :selected="selected.etat=='Abondenner'" style="background-color: red" value="Abondenner">Abondenner</option>
            <option :selected="selected.etat=='A traiter'" style="background-color: yellow" value="A traiter">A traiter</option>
            <option :selected="selected.etat=='Pris en charge par Berrechid'" style="background-color: orange" value="Pris en charge par Berrechid">Pris en charge par Berrechid</option>
          </select>
        </div>
        <div v-if="editError" style="color: yellow; text-align: center">
          Name and Email and Date required!
        </div>
        <div style="text-align: center">
          <button v-on:click="edit" class="btn edit"><i class="fa fa-edit"></i></button>
        </div>
      </div>
    </div>
    <div class="TableTitle">
      <img src="@/assets/icons/student.png" width="50" alt="" /> Clients (
      <span id="Totcurr">{{ total }}</span> {{ "/" + total }})
    </div>
    <div class="toolbox">
      <div style="margin: auto; text-align: center">
        <button v-on:click="showAdd" class="btn add">
          <i class="fa fa-plus-square"></i>
        </button>
        <button
          v-if="supp == true"
          v-on:click="showSupp"
          :disabled="selected.idClient ? false : true"
          :class="!selected.idClient ? 'btn delete enable' : 'btn delete'"
        >
          <i class="fa fa-trash"></i>
        </button>
        <button
          v-on:click="showEdit"
          :disabled="selected.idClient ? false : true"
          :class="!selected.idClient ? 'btn edit enable' : 'btn edit'"
        >
          <i class="fa fa-edit"></i>
        </button>
        <button
          v-on:click="showShow"
          :disabled="selected.idClient ? false : true"
          :class="!selected.idClient ? 'btn edit enable' : 'btn edit'"
        >
          <i class="fa fa-sticky-note"></i>
        </button>
      </div>
      <div class="searchbar">
        <div style="border: 1px solid black; padding: 3px; margin: 0 3px">
          filter de <input type="date" id="date2" /> à <input type="date" id="date1" />
          <button v-on:click="filterDate" class="btn edit">
            <i class="fa fa-search"></i>
          </button>
          <button v-on:click="clear" class="btn delete">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div>
          <input
            type="checkbox"
            id="localFilter"
            v-model="local"
            @click="local == false ? (Enligne=false) : null"
          />
          Afficher local
        </div>
        <div>
          <input
            type="checkbox"
            id="localFilter"
            v-model="Enligne"
            @click="Enligne == false ? (local=false) : null"
          />
          Afficher en ligne
        </div>
        <select id="Setat" @change="filter">
          <option value="">Filter by état</option>
          <option style="background-color: green" value="Déjà inscrit (e)">
            Déjà inscrit (e)
          </option>
          <option style="background-color: red" value="Abondenner">Abondenner</option>
          <option style="background-color: yellow" value="A traiter">A traiter</option>
          <option style="background-color: orange" value="Pris en charge par Berrechid">Pris en charge par Berrechid</option>
        </select>
        <img src="@/assets/icons/search.png" width="25" alt="" />
        <input type="text" id="search" v-on:keyup="searchh" placeholder="search" />
      </div>
    </div>
    <div class="customersCnt">
      <table id="customers">
        <thead>
          <tr style="cursor: initial">
            <th class="hiddenCol">Id Client</th>
            <th>Name</th>
            <th>Téléphone</th>
            <th class="hiddenCol">Email</th>
            <th class="hiddenCol">Date de naissance</th>
            <th>Age</th>
            <th>Ville</th>
            <th class="hiddenCol">Created</th>
            <th>Etat</th>
            <th style="max-width: 5px; min-width: 8px; padding: 2px">
              <button
                v-on:click="showSuppA"
                class="btn delete"
                style="width: 100%; margin: 0;background-color: transparent !important;"
              >
                <i class="fa fa-trash"></i>
              </button>
            </th>
            <th class="hiddenCol" style="padding: 0; width: 15px; min-width: 0px"></th>
          </tr>
        </thead>
        <div class="tablesScroll">
          <tr
            v-for="d in data"
            :key="d.idClient"
            
            :style="(local && d.local == '0') ? 'display:none' : (Enligne && d.local == '1') ? 'display:none' : ''"
            v-on:click="select(d)"
            :id="d.idClient"
          >
            <td :class="
              d.ville == 'berrechid' && d.tested == '1' && d.local == '0'
                ? 'blue'
                : d.ville == 'berrechid' && d.tested == '0' && d.local == '0'
                ? 'move'
                : d.local != '1'
                ? 'refuser'
                : 'locall'
            ">{{ d.idClient ? d.idClient : "null" }}</td>
            <td >{{ d.NomClient ? d.NomClient : "null" }}</td>
            <td>{{ d.TelClient ? d.TelClient : "null" }}</td>
            <td class="hiddenCol">{{ d.EmailClient ? d.EmailClient : "null" }}</td>
            <td class="hiddenCol">{{ d.DateClient ? d.DateClient : "null" }}</td>
            <td class="hiddenCol">{{ new Date().getFullYear() - new Date(d.DateClient).getFullYear() }}</td>
            <td>{{ d.ville ? d.ville : "null" }}</td>
            <td class="hiddenCol">{{ d.created ? d.created : "null" }}</td>
            <td
              :class="
                d.etat == 'Déjà inscrit (e)'
                  ? 'inscrit'
                  : d.etat == 'A traiter'
                  ? 'appeler'
                  : d.etat == 'Abondenner'
                  ? 'refuse'
                  : d.etat == 'Pris en charge par Berrechid'
                  ? 'prisencharge'
                  : ''
              "
            >
              {{ d.etat ? d.etat : "null" }}
            </td>
            <td
              style="
                max-width: 5px;
                min-width: 8px;
                padding: 2px;
                text-align: center;
                background-color: #3c3c3c;
              "
            >
              <input name="deleteAll" :value="d.idClient" type="checkbox" />
            </td>
          </tr>
          
          <tr>
            <td colspan="10">
              <button @click="showMore()">Afficher Plus</button>
            </td>
          </tr>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import { Rest } from "@/providers/rest";

export default {
  name: "Clients",
  data() {
    return {
      panelAdd: false,
      panelEdit: false,
      selected: {},
      editError: false,
      addError: false,
      panelSupp: false,
      panelSuppA: false,
      fr: false,
      en: false,
      panelShow: false,
      Stest: {},
      det: [],
      ann: false,
      frAnn: [],
      allAnn: [],
      enAnn: [],
      annLvl: "",
      local: false,
      Enligne: false,
      Ccomp: 0,
      Cqcm: 0,
      Caudio: 0,
      Icomp: 0,
      Iqcm: 0,
      Iaudio: 0,
      autre: false,
      from: 0,
      count: 50,
      data: [],
      loader: false,
    };
  },
  props: [ "supp", "test", "total", "user","matiers"],
  beforeMount: function () {
    this.loadData();
  },
  methods: {
    showMore(){
      this.from += this.count;
      this.loadData();
    },
    loadData(){
      console.log({from:this.from,count:this.count})
      this.loader = true;
      new Rest().getClinets({from:this.from,count:this.count}).then((r) => {
        if (r.data.success) {
          this.data = this.data.concat(r.data.data);
          this.loader = false;
        } else if (r.data.success == false) {
          this.loader = false;
          alert(r.data.msg);
        } else {
          this.loader = false;
          alert("error de serveur!");
        }
      }).catch(e=>{alert("error de serveur!");});
    },
    toPdf() {
      const pdf = document.getElementById("detPdf").innerHTML;
      let style = "<style>";
      style =
        style + "td,th{padding: 2px;border-color: black;overflow-wrap: break-word;}";
      style =
        style +
        "td:nth-child(1),th:nth-child(1){width: 10%;max-width: 150px;min-width: 150px;font-weight: bold;}";
      style = style + "td:nth-child(1),th:nth-child(2){width: 50%;max-width: 450px;}";
      style = style + "td:nth-child(2),th:nth-child(3){width: 18%;max-width: 186px;}";
      style = style + "td:nth-child(3),th:nth-child(4){width: 18%;max-width: 186px;}";
      style = style + "td:nth-child(4),th:nth-child(5){width: 4%;max-width: 71px;}";
      style = style + "th{background-color:gray}th:nth-child(2){background-color:green}";
      style = style + "</style>";
      const win = window.open("", "", "height=700,width=700");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.selected.NomClient + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write(pdf);
      win.document.write("</body></html>");
      win.document.close();
      win.print();
    },
    toAnnPdf(pdff) {
      const pdf = document.getElementById(pdff).innerHTML;
      let style = "<style>";
      style =
        style + "td,th{padding: 8px 3px;border-color:black;overflow-wrap: break-word;}";
      style = style + "td:nth-child(1),th:nth-child(1){width: 15%;max-width: 200px;}";
      style = style + "td:nth-child(2),th:nth-child(2){width: 15%;max-width: 200px;}";
      style = style + "td:nth-child(3),th:nth-child(3){width: 35%;max-width: 400px;}";
      style = style + "td:nth-child(4),th:nth-child(4){width: 35%;max-width: 400px;}";
      style =
        style +
        "th{background-color:gray}th:nth-child(1),th:nth-child(2){background-color:green}";
      style = style + "</style>";
      const win = window.open("", "", "height=700,width=700");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.selected.NomClient + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write(pdf);
      win.document.write("</body></html>");
      win.document.close();
      win.print();
    },
    Analyse() {
      this.unselect();
      this.ann = true;
      let data = [];

      
      new Rest().getAllDet(this.selected.idClient).then((r) => {
        if (r.data.success) {
          data = r.data.data;
          const counts = [];
          for(const mat of this.matiers){
            counts[mat.idMatiere] = {
              length: 0,
              compi: 0,
              complength: 0,
              qcmi: 0,
              qcmlength: 0,
              audioi: 0,
              audiolength: 0,
              LibelleMatiere: mat.LibelleMatiere,
              idMatiere: mat.idMatiere,
            };
          }
          for (const d of data) {
            for(const mat of this.matiers){
              if (d.idMat == mat.idMatiere) {
                counts[d.idMat].length++;
                if (d.type == "comp") {
                  counts[d.idMat].complength++;
                  if (d.reslut == "true") {
                    counts[d.idMat].compi++;
                  }
                } else if (d.type == "qcm") {
                  counts[d.idMat].qcmlength++;
                  if (d.reslut == "true") {
                    counts[d.idMat].qcmi++;
                  }
                } else if (d.type == "audio") {
                  counts[d.idMat].audiolength++;
                  if (d.reslut == "true") {
                    counts[d.idMat].audioi++;
                  }
                }
              }
            }
          }

          for(const count of counts){
            if(count != undefined)
            if(count.length != 0){
              const matAnn = {LibelleMatiere: count.LibelleMatiere , data: [],idMatiere: count.idMatiere,};
              const frcomp = (count.compi * 100) / count.complength;
              const frqcm = (count.qcmi * 100) / count.qcmlength;
              const fraudio = (count.audioi * 100) / count.audiolength;
              let compobs = "";
              let compobj = "";
              let qcmobs = "";
              let qcmobj = "";
              let audioobs = "";
              let audioobj = "";
              let ann = [];
              new Rest().getAnn().then((a) => {
                if (a.data.success) {
                  ann = a.data.data;
                  for (const an of ann) {
                    if (an.type == "Compréhension") {
                      if (an.percent <= frcomp) {
                        compobs = count.idMatiere != 9 ? an.observ : an.enobs;
                        compobj = count.idMatiere != 9 ? an.objectif : an.enobj;
                      }
                    } else if (an.type == "qcm") {
                      if (an.percent <= frqcm) {
                        qcmobs = count.idMatiere != 9 ? an.observ : an.enobs;
                        qcmobj = count.idMatiere != 9 ? an.objectif : an.enobj;
                      }
                    } else if (an.type == "Media") {
                      if (an.percent <= fraudio) {
                        audioobs = count.idMatiere != 9 ? an.observ : an.enobs;
                        audioobj = count.idMatiere != 9 ? an.objectif : an.enobj;
                      }
                    }
                  }
                  matAnn.data.push({
                    type: "Compréhension écrite",
                    percent: frcomp.toFixed(1),
                    obs: compobs,
                    obj: compobj,
                  });
                  matAnn.data.push({
                    type: "Structure de la langue",
                    percent: frqcm.toFixed(1),
                    obs: qcmobs,
                    obj: qcmobj,
                  });
                  matAnn.data.push({
                    type: "Compréhension orale",
                    percent: fraudio.toFixed(1),
                    obs: audioobs,
                    obj: audioobj,
                  });
                  this.allAnn.push(matAnn);
                }
              });
            }
          }
        }
      });
    },
    selectTest(nv, mat) {
      this.unselect();
      for (const tst of this.test) {
        if (
          tst.idClient == this.selected.idClient &&
          tst.LibelleNiveau == nv &&
          tst.LibelleMatiere == mat
        ) {
          this.Stest = tst;
          new Rest().getDet(tst.idTest).then((r) => {
            if (r.data.success) {
              this.det = r.data.data;
              this.Ccomp = 0;
              this.Caudio = 0;
              this.Cqcm = 0;
              this.Icomp = null;
              this.Iaudio = null;
              this.Iqcm = null;
              for (let i = 0; i < this.det.length; i++) {
                if (this.det[i].type == "comp") {
                  if (this.Icomp == null) this.Icomp = i;
                  this.Ccomp++;
                } else if (this.det[i].type == "qcm") {
                  if (this.Iqcm == null) this.Iqcm = i;
                  this.Cqcm++;
                } else if (this.det[i].type == "audio") {
                  if (this.Iaudio == null) this.Iaudio = i;
                  this.Caudio++;
                }
              }
            }
          });
          break;
        }
      }
    },
    showShow() {
      this.panelShow = true;
    },
    unselect() {
      this.enAnn = [];
      this.frAnn = [];
      this.det = [];
      this.Stest = {};
      this.ann = false;
    },
    hideShow() {
      this.panelShow = false;
      this.unselect();
    },
    hideAdd: function () {
      this.panelAdd = false;
    },
    showAdd: function () {
      this.panelAdd = true;
    },
    hideEdit: function () {
      this.panelEdit = false;
    },
    showEdit: function () {
      this.panelEdit = true;
    },
    hideSupp: function () {
      this.panelSupp = false;
    },
    showSupp: function () {
      this.panelSupp = true;
    },
    hideSuppA: function () {
      this.panelSuppA = false;
    },
    showSuppA: function () {
      this.panelSuppA = true;
    },
    delet() {
      this.$parent.load = true;
      const data = {
        type: "client",
        id: this.selected.idClient,
      };
      new Rest()
        .deleteAdmin(data)
        .then((r) => {
          this.hideSupp();
          if (r.data.success) {
            this.snackBar(r.data.msg);
            this.select(this.selected);
            this.$parent.load = false;
            this.$parent.loadd();
          } else {
            alert(r.data.msg);
            this.$parent.load = false;
          }
        })
        .catch((e) => {
          alert(e);
          this.$parent.load = false;
        });
    },
    deleteAll() {
      const all = document.getElementsByName("deleteAll");
      for (const a of all) {
        if (a.checked == true) {
          this.$parent.load = true;
          const data = {
            type: "client",
            id: a.value,
          };
          new Rest()
            .deleteAdmin(data)
            .then((r) => {
              this.hideSuppA();
              if (r.data.success) {
                this.snackBar(r.data.msg);
                this.$parent.load = false;
                this.$parent.loadd();
              } else {
                alert(r.data.msg);
                this.$parent.load = false;
              }
            })
            .catch((e) => {
              alert(e);
              this.$parent.load = false;
            });
          a.checked = false;
        }
      }
    },
    select(d) {
      if (this.selected.idClient == d.idClient) {
        this.selected = {};
        document.getElementById(d.idClient).className = document
          .getElementById(d.idClient)
          .className.replace(" selected", "");
      } else {
        this.selected = d;
        for (const selected of document.getElementsByClassName("selected")) {
          selected.className = selected.className.replace(" selected", "");
        }
        document.getElementById(d.idClient).className += " selected";
      }
    },
    edit() {
      this.$parent.load = true;
      const name = document.getElementById("editName").value;
      const tel = document.getElementById("editTel").value;
      const email = document.getElementById("editEmail").value;
      const date = document.getElementById("editDate").value;
      const etat = document.getElementById("etat").value;
      if (!name || !date) {
        this.editError = true;
        this.$parent.load = false;
      } else {
        const data = {
          type: "client",
          id: this.selected.idClient,
          name: name,
          tel: tel,
          email: email,
          date: date,
          niveau: "",
          etat: etat,
          user: this.user,
        };
        new Rest()
          .updateAdmin(data)
          .then((r) => {
            this.hideEdit();
            if (r.data.success) {
              this.snackBar(r.data.msg);
              this.select(this.selected);
              this.$parent.load = false;
              this.$parent.loadd();
            } else {
              alert(r.data.msg);
              this.$parent.load = false;
            }
          })
          .catch((e) => {
            alert(e);
            this.$parent.load = false;
          });
      }
    },
    snackBar: function (msg) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 5000);
      }
    },
    add() {
      this.$parent.load = true;
      const name = document.getElementById("addName").value;
      const tel = document.getElementById("addTel").value;
      const email = document.getElementById("addEmail").value;
      const date = document.getElementById("addDate").value;
      let ville = "";
      if (document.getElementById("addVille"))
        ville = document.getElementById("addVille").value;
      if (!name || !date) {
        this.addError = true;
        this.$parent.load = false;
      } else if (!this.fr && !this.en) {
        this.addError = true;
        this.$parent.load = false;
      } else {
        const data = {
          type: "client",
          id: this.selected.idClient,
          name: name,
          tel: tel,
          email: email,
          date: date,
          niveau: "",
          fr: this.fr,
          en: this.en,
          local: "0",
          ville: this.autre == true ? ville : "berrechid",
        };
        new Rest()
          .addAdmin(data)
          .then((r) => {
            this.hideAdd();
            if (r.data.success) {
              this.snackBar(r.data.msg);
              this.$parent.load = false;
              this.$parent.loadd();
            } else {
              alert(r.data.msg);
              this.$parent.load = false;
            }
          })
          .catch((e) => {
            alert(e);
            this.$parent.load = false;
          });
      }
    },
    searchh() {
      let curr = 0;
      const txt = document.getElementById("search").value.toLowerCase().trim();
      const d2 = new Date(document.getElementById("date1").value)
        .toLocaleDateString()
        .split("/");
      const d1 = new Date(document.getElementById("date2").value)
        .toLocaleDateString()
        .split("/");
      const M = document.getElementById("Setat").value;
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (M == "") {
          if (d1.length == 1) {
            if (this.local == false) {
              if (t.innerText.toLowerCase().indexOf(txt) == -1) {
                if(t.className.indexOf(" hiddden")==-1)
                  t.className += " hiddden";
              } else {
                t.className = t.className.replace(" hiddden", "");
                curr++;
              }
            } else {
              if (
                t.innerText.toLowerCase().indexOf(txt) != -1 &&
                t.className == "locall"
              ) {
                t.className = t.className.replace(" hiddden", "");
                curr++;
              } else {
                if(t.className.indexOf(" hiddden")==-1)
                  t.className += " hiddden";
              }
            }
          } else {
            const c = new Date(t.children[7].innerText).toLocaleDateString().split("/");
            const from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
            const to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
            const check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
            const v = check >= from && check <= to;
            if (t.innerText.toLowerCase().indexOf(txt) != -1 && v) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
              document.getElementById("localFilter").checked = false;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
                t.className += " hiddden";
            }
          }
        } else {
          if (
            t.innerText.toLowerCase().indexOf(txt) != -1 &&
            t.children[8].innerText == M
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr += 1;
            document.getElementById("localFilter").checked = false;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    filter() {
      let curr = 0;
      const s = document.getElementById("search").value.toLowerCase().trim();
      const M = document.getElementById("Setat").value;
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (s == "") {
          if (t.children[8].innerText != M && M != "") {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else {
            curr++;
            t.className = t.className.replace(" hiddden", "");
          }
        } else {
          if (M != "") {
            if (
              t.innerText.toLowerCase().indexOf(s) != -1 &&
              t.children[8].innerText == M
            ) {
              curr++;
              t.className = t.className.replace(" hiddden", "");
              document.getElementById("localFilter").checked = false;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
                t.className += " hiddden";
            }
          } else {
            if (t.innerText.toLowerCase().indexOf(s) != -1) {
              curr++;
              t.className = t.className.replace(" hiddden", "");
              document.getElementById("localFilter").checked = false;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
                t.className += " hiddden";
            }
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    filterDate() {
      let curr = 0;
      const s = document.getElementById("search").value.toLowerCase().trim();
      const d2 = new Date(document.getElementById("date1").value)
        .toLocaleDateString()
        .split("/");
      const d1 = new Date(document.getElementById("date2").value)
        .toLocaleDateString()
        .split("/");
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        const c = new Date(t.children[7].innerText).toLocaleDateString().split("/");
        const from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
        const to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        const check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
        const v = check >= from && check <= to;
        if (s == "") {
          if (!v) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else {
            curr++;
            t.className = t.className.replace(" hiddden", "");
          }
        } else {
          if (t.innerText.toLowerCase().indexOf(s) != -1 && v) {
            curr++;
            t.className = t.className.replace(" hiddden", "");
            document.getElementById("localFilter").checked = false;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    clear() {
      let curr = 0;
      const s = document.getElementById("search").value.toLowerCase().trim();
      document.getElementById("date1").value = "";
      document.getElementById("date2").value = "";
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (s == "") {
          curr++;
          t.className = t.className.replace(" hiddden", "");
        } else {
          if (t.innerText.toLowerCase().indexOf(s) != -1) {
            curr++;
            t.className = t.className.replace(" hiddden", "");
            document.getElementById("localFilter").checked = false;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    copy(id) {
      const copy = document.getElementById(id);
      copy.disabled = false;
      copy.select();
      copy.disabled = true;
      document.execCommand("copy");
    },
  },
};
</script>

<style>
.hiddden{
  display: none;
}

@media screen and (max-width: 1000px) {
  #customers {
    font-size: 13px;
  }
  #customers td,
  #customers th {
    padding: 3px !important;
  }
  .testsBar {
    width: 100% !important;
    height: fit-content !important;
    min-height: unset !important;
    display: flex;
  }
  .testsAnCnt {
    width: 100% !important;
    display: block !important;
  }
  .testsCnt {
    width: 100% !important;
  }
  .testsscroll {
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  #customers td,
  #customers th {
    padding: 2px !important;
  }
  .clients .hiddenCol {
    display: none !important;
  }
  .sousQcus {
    width: 100% !important;
  }
  .searchbar {
    display: block !important;
    text-align: center !important;
  }
}
.hiddenDiv {
  display: none;
}
#Tabledet td,
#Tabledet th {
  padding: 2px;
  border-color: black;
  overflow-wrap: break-word;
}
#Tabledet td:nth-child(1),
#Tabledet th:nth-child(1) {
  width: 10%;
  max-width: 150px;
  min-width: 150px;
  font-weight: bold;
}
#Tabledet td:nth-child(2),
#Tabledet th:nth-child(2) {
  width: 50%;
  max-width: 450px;
  min-width: 225px;
}
#Tabledet td:nth-child(4),
#Tabledet th:nth-child(4) {
  width: 21%;
  max-width: 200px;
  min-width: 100px;
}
#Tabledet td:nth-child(3),
#Tabledet th:nth-child(3) {
  width: 21%;
  max-width: 186px;
  min-width: 93px;
}
#Tabledet td:nth-child(5),
#Tabledet th:nth-child(5) {
  width: 8%;
  max-width: 71px;
  min-width: 65px;
}
#Tabledet th {
  background-color: gray;
}
#Tabledet th:nth-child(2) {
  background-color: green;
}

#TableAnn td,
#TableAnn th {
  padding: 3px;
  border-color: black;
  overflow-wrap: break-word;
}
#TableAnn td:nth-child(1),
#TableAnn th:nth-child(1) {
  width: 15%;
  max-width: 200px;
  min-width: 100px;
}
#TableAnn td:nth-child(3),
#TableAnn th:nth-child(3) {
  width: 35%;
  max-width: 400px;
  min-width: 200px;
}
#TableAnn td:nth-child(2),
#TableAnn th:nth-child(2) {
  width: 15%;
  max-width: 200px;
  min-width: 100px;
}
#TableAnn td:nth-child(4),
#TableAnn th:nth-child(4) {
  width: 35%;
  max-width: 400px;
  min-width: 200px;
}
#TableAnn th {
  background-color: gray;
}
#TableAnn th:nth-child(1),
#TableAnn th:nth-child(2) {
  background-color: green;
}

.testsBar {
  width: 20%;
  background-color: #d1d1d1;
  padding: 20px 0;
  min-height: 500px;
}
.testsCnt {
  width: 80%;
}
.testsCnt div {
  text-align: center;
}
.testsBar div:nth-child(1) {
  text-align: center;
  color: blue;
  font-weight: bold;
  font-size: 19px;
  border-bottom: 1px blue solid;
  cursor: initial;
}
.nodata {
  cursor: initial !important;
  margin-top: 20px;
  border-bottom: none !important;
}
.testsBar div {
  text-align: center;
  border-bottom: 1px black solid;
  padding: 5px;
  cursor: pointer;
}
.TableTitle {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
}
.TableTitle img {
  margin-right: 10px;
}
.clients {
  position: relative;
  height: 100%;
}
.customersCnt {
  height: calc(100% - 133px);
  max-height: calc(100% - 133px);
}
.tablesScroll {
  overflow-y: scroll;
  height: calc(100vh - 268px);
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 99.9%;
  margin: auto;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 30px;
  overflow-wrap: break-word;
  min-width: 30px;
}
tr {
  display: inline-table;
  width: 99.9%;
  cursor: pointer;
}
thead tr {
  display: inline-table !important;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #3c3c3c !important;
  color: white;
}
.toolbox {
  padding: 10px;
  display: flex;
  background-color: #d2d2d2 !important;
  border-top: 1px solid #e9e9eb !important;
}
.searchbar {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.searchbar input {
  border-radius: 20px;
  padding: 5px;
  font-size: 15px;
}
.searchbar input::placeholder {
  text-align: center;
}
.searchbar img {
  margin-right: 5px;
}
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 7px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
  border-radius: 10px;
}
.add {
  background-color: #1c8c00 !important;
}
.delete {
  background-color: #d5161a !important;
}
.edit {
  background-color: DodgerBlue;
}
.add:hover {
  background-color: #4dd62b9e;
}
.delete:hover {
  background-color: #ff00009e;
}
.edit:hover {
  background-color: #1e90ff7d;
}
.toolPanel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff85;
  z-index: 2;
  display: flex;
  align-items: center;
}
.toolPanel .cc {
  width: fit-content;
  margin: auto;
  padding: 20px 50px;
  border-radius: 20px;
  position: relative;
  background-color: #d2d2d2;
  border: 1px solid #e9e9eb;
  background-image: none !important;
  box-shadow: 0px 0px 4px 0px #616161;
}
.cc .ccTitle {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: black !important;
  margin-bottom: 20px;
}
.cc .ccChild {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 5px;
  font-weight: bold;
}
.ccChild span {
  display: flex;
  align-items: center;
}
.ccChild input {
  padding: 5px;
  font-size: 15px;
}
.x {
  position: absolute;
  top: 0;
  right: 0;
  color: black !important;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  border-top-right-radius: 20px;
  cursor: pointer;
}
.x:hover {
  background-color: red;
  color: #fafafc;
}
.enable {
  cursor: no-drop !important;
}
.selected {
  background-color: #7777ffa1 !important;
}
.selected:hover {
  background-color: #7777ffa1 !important;
}
.blue {
  background-color: #6c6cf3 !important;
  color: #cecece;
}
.move {
  background-color: #c878e0 !important;
}
.inscrit {
  background-color: #60ff60 !important;
}
.refuser {
  background-color: #ababab !important;
}

.refuse {
  background-color: #ff4646 !important;
}

.prisencharge{
  background-color: orange !important;
}

.appeler {
  background-color: #f9f979  !important;
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
/* Darker background on mouse-over */
</style>
